<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">预习资料详情</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="main-contain">
        <!-- <div class="title">基础医学预习</div> -->
        <div class="contain">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }"
            :row-style="{
  'font-size': '15px',
  color: '#222222',
  'font-weight': '400',
}"
          >
            <el-table-column label="课程" align="center">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.course_lib_name ? scope.row.course_lib_name : "-"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实验项目" align="center">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.experiment_name ? scope.row.experiment_name : "-"
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="main-contain">
        <div class="title2">预习资料:</div>
        <div class="contain">
          <el-table
            ref="singleTable"
            :data="tableData2"
            highlight-current-row
            style="width: 100%"
            class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }"
            :row-style="{
  'font-size': '15px',
  color: '#222222',
  'font-weight': '400',
}"
          >
            <el-table-column label="文件名">
              <template slot-scope="scope">
                <div class="item-left">
                  <el-image class="elimage" :src="icon1" />
                  <div class="title">{{ scope.row.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="大小">
              <template slot-scope="scope">
                <div class="item-middle">
                  <div class="title">{{ scope.row.size }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="item-right">
                  <div class="bt" @click="handelCheck(scope.row)">查看</div>
                  <div class="line2"></div>
                  <div class="bt" @click="handelDownload(scope.row)">下载</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog title="文件预览" :visible.sync="dialogVisible" width="50%" @close="close_dialog">
      <template v-if="type == '视频'">
        <video
          class="video_view"
          ref="vueRef"
          width="100%"
          height="500"
          controls
          controlslist="nodownload noplaybackrate"
          disablepictureinpicture
          :src="videoviewurl"
        />
      </template>
      <template v-if="type == '图片'">
        <iframe class="iframe_cont" :src="imageviewurl" frameborder="0" ref="myiframe"></iframe>
      </template>
      <template v-if="type == '其他'">
        <iframe class="iframe_cont" :src="file_url" frameborder="0" ref="myiframe"></iframe>
      </template>
      <template v-if="type == 'PDF'">
        <iframe class="iframe_cont" :src="fileurl" frameborder="0" ref="myiframe"></iframe>
      </template>
      <div
        id="toolbar"
        :class="toolbar == false ?'tool-box doc_1': 'tool-box'"
        style="visibility: visible;"
      >
        <div class="line-bar1"></div>
        <div class="full-box">
          <a class="full-btn1" @click="screen">
            <i class="el-icon-full-screen" style="font-size:17px"></i>
            <span style="margin-left:4px">全屏</span>
          </a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getattachmentlist, operateAttachment } from "@/api/attachment"
import { Base64 } from 'js-base64'
import screenfull from 'screenfull'
const request_base_path = require('@/utils/base_url')
export default {
  name: "mainpage",
  components: {},
  data () {
    return {
      filePreview: request_base_path.pro_url + 'file-preview/onlinePreview?url=', // 数据预览
      picturesPreview: request_base_path.pro_url + 'file-preview/picturesPreview?urls=', // 多图预览
      fileTask: request_base_path.pro_url + 'file-preview/addTask?url=', // 数据预埋

      radio: "1",
      searchvalue: "",
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      tableData2: [],
      item: "",
      type: "",
      dialogVisible: false,
      imageviewurlList: [], // 图片数据
      isImg: ["jpg", "png", "jpeg", "gif", "webp"],
      fileurl: "",
      file_url: "",
      videoviewurl: "",
      imageviewurl: "",
      toolbar: false,
    }
  },
  computed: {},
  created () {
    this.item = this.$route.query
    // //console.log("JSON.parse(str)", JSON.parse(str))
    this.tableData.push({
      course_lib_name: this.item.course_lib_name,
      experiment_name: this.item.experiment_name,
    })

    this.getattachmentlist({ related_id: this.item.id, type: 1, per_page: 100 })
  },
  methods: {
    toback () {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 2,
          componentId: "Teachingexperimentclass",
        },
      })
    },
    getattachmentlist (params) {
      getattachmentlist(params)
        .then((res) => {
          res.data.data.forEach(el => {
            this.$set(el, 'size', this.convertSizeUnits(el.size))
            if (this.isImg.includes(el.ext)) {
              this.imageviewurlList.push(el.url)
            }
          })
          this.tableData2 = res.data.data
        })
        .catch((error) => {
          //console.log(error)
        })
    },
    // 下载
    handelDownload (row) {
      let link = document.createElement("a")
      link.style.display = "none"
      link.href = row.url
      link.setAttribute("download", row.name) // 文件名
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // 下载完成移除元素
      let par = {
        attachment_id: row.id,
        action: 1
      }
      operateAttachment(par).then((res) => { })
    },
    // 查看
    async handelCheck (item) {
      this.resetFill()
      await this.getTask(item.url)
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        this.type = "视频"
        this.videoviewurl = item.url
        this.toolbar = false
      } else if (this.isImg.includes(item.ext)) {
        this.type = "图片"
        let len = this.imageviewurlList.length
        for (let i = 0; i < this.imageviewurlList.length; i++) {
          if (this.imageviewurlList[i] === item.url) {
            let newArr = this.imageviewurlList.splice(i, len - i)
            let oldArr = this.imageviewurlList
            this.imageviewurlList = [...newArr, ...oldArr]
            break
          }
        }
        let url = this.imageviewurlList.join("|")
        this.imageviewurl = this.picturesPreview + encodeURIComponent(Base64.encode(url))
        this.toolbar = true
      } else if (item.ext == "pdf") {
        this.type = "PDF"
        this.fileurl = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      } else {
        this.type = "其他"
        this.file_url = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      }
      this.dialogVisible = true
    },
    resetFill () {
      this.imageviewurl = ''
      this.fileurl = ''
      this.file_url = ''
    },
    getTask (data) {
      this.fileTask + data
    },
    close_dialog () {
      this.resetFill()
      this.dialogVisible = false
      let _vueRef = this.$refs.vueRef
      if (this.type === '视频') {
        _vueRef.pause() //暂停
      }
    },
    //全屏
    screen () {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.myiframe)
      }
    },
    convertSizeUnits (size) {
      if (size >= 1073741824) {
        size = Math.round(size / 1073741824 * 100) / 100 + 'GB'
      } else if (size >= 1048576) {
        size = Math.round(size / 1048576 * 100) / 100 + 'MB'
      } else if (size >= 1024) {
        size = Math.round(size / 1024 * 100) / 100 + 'KB'
      } else {
        size = size + '字节'
      }
      return size
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 240px);
  background: #ffffff;
  margin-top: 30px;

  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
      margin-top: 10px;
      margin-left: 20px;
      // margin-bottom: 20px;
    }

    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }

  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .main-contain {
      margin: 10px;
      margin-bottom: 60px;
      width: 100%;

      .title {
        font-size: 26px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 10px;
      }

      .title2 {
        font-size: 20px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 10px;
      }

      .contain {
        width: 100%;
        height: 100%;

        .Table {
          width: 100%;

          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
              margin-left: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-middle {
            .title {
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;

            .bt {
              font-size: 16px;
              font-weight: 400;
              color: #3d84ff;
              user-select: none;
              cursor: pointer;
            }

            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }
      }
    }
  }
}
#toolbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}
</style>
